<template>
  <div>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      tooltip-effect="dark"
      style="width: 100%;"
      :header-cell-style="{background:'#F8F8F8',color:'#333'}"
    >
      <el-table-column
        prop="doctorId"
        label="医生ID"
        align="center"
      />
      <el-table-column
        prop="doctorName"
        label="医生名称"
        align="center"
      />
      <el-table-column
        label="机构"
        align="center"
        prop="orgName"
      />
      <!-- <el-table-column
        label="加入项目"
        align="center"
        prop="projectName"
      /> -->
      <el-table-column
        label="角色"
        align="center"
        prop="role"
      >
        <template slot-scope="scope">
          {{ scope.row.role ? roleMap[scope.row.role] : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="加入时间"
        align="center"
        width="100px"
      >
        <template slot-scope="scope">
          {{ scope.row.createdAt || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="140px"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="viewDetail(scope.row)"
          >
            详情
          </el-button>
          <el-popconfirm
            v-per="'surveya013'"
            title="确定删除吗？"
            style="margin-left: 12px;"
            @confirm="deleteItem(scope.row)"
          >
            <el-button
              slot="reference"
              type="text"
            >
              删除
            </el-button>
            <!-- <el-button slot="reference">删除</el-button> -->
          </el-popconfirm>
          <!-- <el-button
            type="text"
            @click="deleteItem(scope.row)"
          >
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    formCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('app', {
      roleMap: state => state.white.roleMap
    })
  },
  methods: {
    viewDetail (row) {
      this.$emit('view', row)
    },
    deleteItem (row) {
      this.$emit('delete', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-btn {
  color: #409eff;
  cursor: pointer;
}
.edit {
  color: #666;
}

.eye-open-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-open-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.eye-close-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

</style>
