import { render, staticRenderFns } from "./department.vue?vue&type=template&id=720e1504&scoped=true&"
import script from "./department.vue?vue&type=script&lang=js&"
export * from "./department.vue?vue&type=script&lang=js&"
import style0 from "./department.vue?vue&type=style&index=0&id=720e1504&lang=scss&scoped=true&"
import style1 from "./department.vue?vue&type=style&index=1&lang=scss&global=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720e1504",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/BBW2Berx/0/f2e/f2e-bsp-asset/survey-bsp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('720e1504')) {
      api.createRecord('720e1504', component.options)
    } else {
      api.reload('720e1504', component.options)
    }
    module.hot.accept("./department.vue?vue&type=template&id=720e1504&scoped=true&", function () {
      api.rerender('720e1504', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pi/department.vue"
export default component.exports