var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statistic" }, [
    _vm.showBlock
      ? _c("div", { staticClass: "statistic-block-container" }, [
          _c("div", { staticClass: "statistic-block" }, [
            _c("div", { staticClass: "statistic-title" }, [
              _vm._v(" 患者人数 "),
            ]),
            _c("div", { staticClass: "statistic-content" }, [
              _c("div", { staticClass: "statistic-content-number" }, [
                _vm._v(" " + _vm._s(_vm.patientNumber) + " "),
              ]),
              _c("div", { staticClass: "statistic-content-unit" }, [
                _vm._v(" 人 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistic-block" }, [
            _c("div", { staticClass: "statistic-title" }, [
              _vm._v(" PI 人数 "),
            ]),
            _c("div", { staticClass: "statistic-content" }, [
              _c("div", { staticClass: "statistic-content-number" }, [
                _vm._v(" " + _vm._s(_vm.piNumber) + " "),
              ]),
              _c("div", { staticClass: "statistic-content-unit" }, [
                _vm._v(" 人 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistic-block" }, [
            _c("div", { staticClass: "statistic-title" }, [
              _vm._v(" PI 患病率 "),
            ]),
            _c("div", { staticClass: "statistic-content" }, [
              _c("div", { staticClass: "statistic-content-number" }, [
                _vm._v(" " + _vm._s(_vm.piRate) + " "),
              ]),
              _c("div", { staticClass: "statistic-content-unit" }),
            ]),
          ]),
          _c("div", { staticClass: "statistic-block" }, [
            _c("div", { staticClass: "statistic-title" }, [
              _vm._v(" HAPI 人数 "),
            ]),
            _c("div", { staticClass: "statistic-content" }, [
              _c("div", { staticClass: "statistic-content-number" }, [
                _vm._v(" " + _vm._s(_vm.hapiNumber) + " "),
              ]),
              _c("div", { staticClass: "statistic-content-unit" }, [
                _vm._v(" 人 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistic-block" }, [
            _c("div", { staticClass: "statistic-title" }, [
              _vm._v(" HAPI 患病率 "),
            ]),
            _c("div", { staticClass: "statistic-content" }, [
              _c("div", { staticClass: "statistic-content-number" }, [
                _vm._v(" " + _vm._s(_vm.hapiRate) + " "),
              ]),
              _c("div", { staticClass: "statistic-content-unit" }),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "statistic-inline" }, [
          _c("span", { staticClass: "statistic-inline-text" }, [
            _vm._v("合计患者人数 "),
          ]),
          _c("span", { staticClass: "statistic-inline-number" }, [
            _vm._v(" " + _vm._s(_vm.patientNumber) + " "),
          ]),
          _c("span", { staticClass: "statistic-inline-text" }, [
            _vm._v("人，PI 人数 "),
          ]),
          _c("span", { staticClass: "statistic-inline-number" }, [
            _vm._v(" " + _vm._s(_vm.piNumber) + " "),
          ]),
          _c("span", { staticClass: "statistic-inline-text" }, [
            _vm._v(" 人，PI 患病率 "),
          ]),
          _c("span", { staticClass: "statistic-inline-number" }, [
            _vm._v(" " + _vm._s(_vm.piRate) + " "),
          ]),
          _c("span", { staticClass: "statistic-inline-text" }, [
            _vm._v("，HAPI 人数 "),
          ]),
          _c("span", { staticClass: "statistic-inline-number" }, [
            _vm._v(" " + _vm._s(_vm.hapiNumber) + " "),
          ]),
          _c("span", { staticClass: "statistic-inline-text" }, [
            _vm._v(" 人，HAPI 患病率 "),
          ]),
          _c("span", { staticClass: "statistic-inline-number" }, [
            _vm._v(" " + _vm._s(_vm.hapiRate) + " "),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }