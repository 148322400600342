<template>
  <div
    class="criteria"
    :class="{ clear : clearWrapper }"
  >
    <el-select
      v-model="year"
      :disabled="loading"
      class="criteria-year"
      @change="changeYear"
    >
      <el-option
        v-for="item in yearOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <div
      class="btn"
      :class="{ active: quarter === 'Q4', opacity: loading }"
      @click="quarterChange('Q4')"
    >
      Q4
    </div>
    <div
      class="btn"
      :class="{ active: quarter === 'Q3', opacity: loading }"
      @click="quarterChange('Q3')"
    >
      Q3
    </div>
    <div
      class="btn"
      :class="{ active: quarter === 'Q2', opacity: loading }"
      @click="quarterChange('Q2')"
    >
      Q2
    </div>
    <div
      class="btn"
      :class="{ active: quarter === 'Q1', opacity: loading }"
      @click="quarterChange('Q1')"
    >
      Q1
    </div>
    <div
      class="btn"
      :class="{ active: quarter === null, opacity: loading }"
      @click="reset"
    >
      全年
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getYearList } from '../../api/project/pi'

export default {
  props: {
    value: {
      type: Object,
      default () {
        return {
          url: '',
          note: ''
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearWrapper: {
      type: Boolean,
      default: false
    },
    y: {
      type: String,
      default: ''
    },
    q: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      year: `${dayjs().year()}`,
      yearOptions: [{
        label: '全部',
        value: '全部'
      }],
      quarter: null,
      show: false,
      textarea2: '',
      datetimeNodeList: []
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getYearList()
    if (this.y) {
      this.year = this.y
    }
    if (this.q) {
      this.quarter = this.q
    }
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    // this.datetimeNodeList = [
    //   dayjs(`${this.year}-03-31 23:59:59`).valueOf(),
    //   dayjs(`${this.year}-06-30 23:59:59`).valueOf(),
    //   dayjs(`${this.year}-09-30 23:59:59`).valueOf(),
    //   dayjs(`${this.year}-12-31 23:59:59`).valueOf()
    // ]
    // for (let i = 0; i < this.datetimeNodeList.length; i++) {
    //   if (dayjs().valueOf() <= this.datetimeNodeList[i]) {
    //     this.quarter = `Q${i + 1}`
    //     break
    //   }
    // }
  },
  methods: {
    reset () {
      // this.year = '全部'
      this.quarter = null
      this.$emit('reset')
    },
    change () {
      this.$emit('change', {
        quarter: this.quarter,
        year: this.year
      })
    },
    quarterChange (quarter) {
      if (this.loading) {
        return
      }
      this.quarter = quarter
      this.change()
    },
    changeYear (year) {
      this.year = year
      this.change()
    },
    async getYearList () {
      const resp = await getYearList()
      if (resp.success) {
        this.yearOptions = [
          {
            label: '全部',
            value: '全部'
          },
          ...resp.data.map(item => ({
            label: item.year + '年',
            value: item.year
          }))]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.criteria {
  margin-top: 16px;
  padding-inline: 16px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 6px;
  &.clear {
    margin-top: 0;
    margin-bottom: 0;
  }

  .criteria-year {
    margin-right: 16px;
  }

  .el-button+.el-button {
    margin-left: 0;
  }
}
.btn {
  padding: 8px 20px;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  background-color: rgb(236, 245, 255);
  border-color: rgb(179, 216, 255);
  color: rgb(59, 145, 235);
}
</style>
