export const parseToRate = (num, decimal = 2) => {
  try {
    return (Number(num) * 100).toFixed(decimal) + '%'
  } catch (e) {
    return '0%'
  }
}

export const parseToPrice = (num, symbol = ',') => {
  try {
    return Number(num).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, symbol)
  } catch (e) {
    return '0'
  }
}
