<template>
  <div class="container">
    <div class="table-pannel">
      <div class="table-head">
        <div class="t-title">
          {{ hospitalName }} {{ sectionType }}  PI 患病率
          <sapn class="tip">
            统计截止时间：{{ date }}
          </sapn>
        </div>
        <div class="action">
          <el-button
            size="small"
            @click="back"
          >
            返回
          </el-button>
        </div>
      </div>
      <div style="display: none">
        <CriteriaComp
          :y="year"
          :q="quarter"
          :loading="loading"
          @reset="reset"
          @change="change"
        />
      </div>
      <StatisticComp
        :patient-number="patientNumber"
        :pi-number="piNumber"
        :pi-rate="piRate"
        :hapi-number="hapiNumber"
        :hapi-rate="hapiRate"
        :show-block="false"
      />
      <div class="table-container">
        <el-table
          ref="table"
          v-loading="loading"
          :data="list"
          style="width: 100%"
          :header-cell-style="{background:'#f7f8fa',color:'#333'}"
          @sort-change="sortChange"
        >
          <el-table-column
            prop="nurseCell"
            label="护理单元"
            align="left"
          />
          <el-table-column
            prop="pCnt"
            label="患者人数"
            align="center"
            sortable
          />
          <el-table-column
            prop="piCnt"
            label="PI 人数"
            align="center"
            sortable
          />
          <el-table-column
            prop="piBl"
            label="PI 患病率"
            align="center"
            sortable
          />
          <el-table-column
            prop="hapiCnt"
            label="HAPI 人数"
            align="center"
            sortable
          />
          <el-table-column
            prop="hapiBl"
            label="HAPI 患病率"
            align="center"
            sortable
          />
        </el-table>
      </div>
      <div class="table-paging">
        <el-pagination
          :disabled="loading"
          background
          :current-page="query.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="query.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="query.total"
          @size-change="changePageSize"
          @current-change="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CriteriaComp from '@/views/pi/criteria.comp.vue'
import StatisticComp from '@/views/pi/statistic.comp.vue'
import { getPINurseUnitListForDepartmentEntry } from '@/api/project/pi'
import { parseToRate, parseToPrice } from '@/utils/transform'
import dayjs from 'dayjs'

export default {
  components: {
    CriteriaComp,
    StatisticComp
  },
  data () {
    return {
      loading: false,
      list: [],
      query: {
        page: 1,
        total: 0,
        size: 10
      },
      size: 10,
      patientNumber: '0',
      piNumber: '0',
      piRate: '0%',
      hapiNumber: '0',
      hapiRate: '0%',
      year: '',
      quarter: '',
      sortField: '',
      sort: '',
      hospitalName: '',
      sectionType: '',
      date: dayjs().subtract(1, 'day').hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm:ss'),
      departmentSortField: '',
      departmentSort: '',
      departmentPage: '',
      departmentSiz: ''
    }
  },
  created () {
    const {
      hospitalName,
      sectionType,
      year,
      quarter,
      departmentSortField,
      departmentSort,
      departmentPage,
      departmentSize
    } = this.$route.query
    this.hospitalName = hospitalName
    this.sectionType = sectionType
    if (year) {
      this.year = year
    } else {
      this.year = `${dayjs().year()}`
    }
    if (quarter) {
      this.quarter = quarter
    } else {
      this.quarter = ''
    }
    if (departmentSortField) {
      this.departmentSortField = departmentSortField
    } else {
      this.departmentSortField = ''
    }
    if (departmentSort) {
      this.departmentSort = departmentSort
    } else {
      this.departmentSort = ''
    }
    if (departmentPage) {
      this.departmentPage = departmentPage
    } else {
      this.departmentPage = 1
    }
    if (departmentSize) {
      this.departmentSize = departmentSize
    } else {
      this.departmentSize = 10
    }
    this.getList()
  },
  methods: {
    back () {
      this.$router.replace({
        path: '/pi/departmentEntry',
        query: {
          year: this.year,
          quarter: this.quarter,
          sortField: this.departmentSortField,
          sort: this.departmentSort,
          page: this.departmentPage,
          size: this.departmentPageSize
        }
      })
    },
    async getPINurseUnitListForDepartmentEntry (page = 1) {
      const params = {
        sectionType: this.sectionType,
        page,
        size: this.size
      }

      if (this.year && this.year !== '全部') {
        params.year = this.year
      }

      if (this.quarter) {
        params.quarter = this.quarter
      }

      if (this.sort) {
        params.sort = this.sort
      }

      if (this.sortField) {
        params.sortField = this.sortField
      }

      this.loading = true
      const resp = await getPINurseUnitListForDepartmentEntry(params)
      this.loading = false
      if (resp.success) {
        this.list = resp.data.list.map(item => {
          item.piBl = parseToRate(item.piBl)
          item.hapiBl = parseToRate(item.hapiBl)
          return item
        })

        this.patientNumber = parseToPrice(resp.data.extend.pCnt)
        this.piNumber = parseToPrice(resp.data.extend.piCnt)
        this.piRate = parseToRate(resp.data.extend.piBl)
        this.hapiNumber = parseToPrice(resp.data.extend.hapiCnt)
        this.hapiRate = parseToRate(resp.data.extend.hapiBl)

        this.query = resp.paging
      }
    },
    reset () {
      // this.year = ''
      this.quarter = ''
      // this.sortField = ''
      // this.sort = ''
      // this.hospital = []
      // this.hospitalLevel = []
      this.getPINurseUnitListForDepartmentEntry()
      // this.$refs.table.clearSort()
    },
    change (data) {
      this.year = data.year
      this.quarter = data.quarter
      this.getPINurseUnitListForDepartmentEntry()
    },
    hospitalChange () {
      this.getPINurseUnitListForDepartmentEntry()
    },
    hospitalLevelChange () {
      this.getPINurseUnitListForDepartmentEntry()
    },
    sortChange ({ prop, order }) {
      this.sortField = prop
      this.sort = order === 'ascending' ? 'asc' : 'desc'
      this.getPINurseUnitListForDepartmentEntry()
    },
    getList () {
      this.getPINurseUnitListForDepartmentEntry()
    },
    changePageSize (pageSize) {
      this.size = pageSize
      this.getPINurseUnitListForDepartmentEntry()
    },
    changePage (page) {
      this.getPINurseUnitListForDepartmentEntry(page)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tip {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: Regular;
    text-align: left;
    color: #666666;
    line-height: 17px;
  }
  .container {
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 45px;
    min-width: 1225px;
    max-width: none;

    .table-pannel {
      background: #feffff;
      border-radius: 8px;
      overflow: hidden;
      .table-head {
        padding: 11px 12px 11px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .t-title {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333;
          line-height: 22px;
        }
      }
      .table-container {
        padding: 0 12px 0 12px;
      }
      .table-paging {
        padding-inline: 14px;
        text-align: left;
        margin: 22px 12px  24px 0;
      }
    }

    .drawer {
      width: 800px !important;
      border-radius: 8px 0px 0px 8px;
      box-shadow: 0px 8px 20px 0px rgba(26,62,153,0.10);
      .el-drawer__header {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: rgba(0,0,0,0.85);
        padding-top: 22px;
        padding-bottom:23px;
        padding-right:21px;
        padding-left: 36px;
        border: 0.5px solid #e6e6e6;
      }
      .dialog-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        .dialog-content {
          flex: 1;
          padding-left: 36px;
          padding-bottom: 60px;
        }
        .dialog-footer {
          height: 88px;
          padding-left: 36px;
          display: flex;
          align-items: center;
          border-top: 1px solid #e6e6e6;
          .dialog-cancel {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .hospital-criteria {
    padding-inline: 14px;
    display: flex;
    align-items: center;
    gap: 7px;
    .hospital-criteria-title {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      line-height: 20px;
    }
  }
  .hospital-select {
    width: 300px;
    .el-input.el-input--suffix {
      input {
        color: red;
      }
      input::placeholder {
        color: #333;
      }
    }
  }
  .hospitallevel-select {
    width: 100px;
    input::placeholder {
      color: #333;
    }
  }
  .link {
    color: #409EFF;
  }
</style>
<style lang="scss" global>
  .hospital-select {
    .el-input.el-input--suffix {
      input::placeholder {
        color: #333;
      }
    }
  }
  .hospitallevel-select {
    .el-input.el-input--suffix {
      input::placeholder {
        color: #333;
      }
    }
  }
</style>
