import PI from '@/views/pi/index.vue'
import PIHospital from '@/views/pi/hospital.vue'
import PIDepartment from '@/views/pi/department.vue'
import PIDepartmentEntry from '@/views/pi/department.entry.vue'
import PIDeparrmentStatistic from '@/views/pi/department.statistic.vue'
import PINurse from '@/views/pi/nurse.vue'
import PINurseDepartmentEntry from '@/views/pi/nurse.department.entry.vue'

export default [
  {
    path: '/pi',
    name: 'wrapper',
    component: PI,
    children: [
      {
        path: 'hospital',
        name: 'hospital',
        component: PIHospital,
        async beforeEnter (to, from, next) {
          document.title = '医院 PI 患病率'
          next()
        }
      },
      {
        path: 'department',
        name: 'department',
        component: PIDepartment,
        async beforeEnter (to, from, next) {
          document.title = '科室 PI 患病率'
          next()
        }
      },
      {
        path: 'departmentStatistic',
        name: 'departmentStatistic',
        component: PIDeparrmentStatistic,
        async beforeEnter (to, from, next) {
          document.title = '科室 PI 患病率'
          next()
        }
      },
      {
        path: 'departmentEntry',
        name: 'departmentEntry',
        component: PIDepartmentEntry,
        async beforeEnter (to, from, next) {
          document.title = '科室 PI 患病率'
          next()
        }
      },
      {
        path: 'nurse',
        name: 'nurse',
        component: PINurse,
        async beforeEnter (to, from, next) {
          document.title = '护理单元'
          next()
        }
      },
      {
        path: 'nurseDepartmentEntry',
        name: 'nurseDepartmentEntry',
        component: PINurseDepartmentEntry,
        async beforeEnter (to, from, next) {
          document.title = '护理单元'
          next()
        }
      }
    ]
  }
]
