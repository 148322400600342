var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "table-pannel" },
      [
        _c("div", { staticClass: "table-head" }, [
          _c(
            "div",
            { staticClass: "t-title" },
            [
              _vm._v(" 科室 PI 患病率 "),
              _c("sapn", { staticClass: "tip" }, [
                _vm._v(" 统计截止时间：" + _vm._s(_vm.date) + " "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "action" }),
        ]),
        _c("CriteriaComp", {
          attrs: { loading: _vm.loading },
          on: { reset: _vm.reset, change: _vm.change },
        }),
        _c(
          "div",
          { staticStyle: { display: "none" } },
          [
            _c("StatisticComp", {
              attrs: {
                "patient-number": _vm.patientNumber,
                "pi-number": _vm.piNumber,
                "pi-rate": _vm.piRate,
                "hapi-number": _vm.hapiNumber,
                "hapi-rate": _vm.hapiRate,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-container" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.list,
                  "header-cell-style": { background: "#f7f8fa", color: "#333" },
                },
                on: { "sort-change": _vm.sortChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "sectionType",
                    label: "科室名称",
                    align: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "pCnt",
                    label: "患者人数",
                    align: "center",
                    sortable: "",
                    width: "200px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "piCnt",
                    label: "PI 人数",
                    align: "center",
                    sortable: "",
                    width: "200px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "piBl",
                    label: "PI 患病率",
                    align: "center",
                    sortable: "",
                    width: "200px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "hapiCnt",
                    label: "HAPI 人数",
                    align: "center",
                    sortable: "",
                    width: "200px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "hapiBl",
                    label: "HAPI 患病率",
                    align: "center",
                    sortable: "",
                    width: "200px",
                  },
                }),
              ],
              1
            ),
            !_vm.loading
              ? _c("div", { staticClass: "foot" }, [
                  _c("div", { staticClass: "foot-title" }, [_vm._v(" 合计 ")]),
                  _c("div", { staticClass: "colunm" }, [
                    _vm._v(" " + _vm._s(_vm.patientNumber) + " "),
                  ]),
                  _c("div", { staticClass: "colunm" }, [
                    _vm._v(" " + _vm._s(_vm.piNumber) + " "),
                  ]),
                  _c("div", { staticClass: "colunm" }, [
                    _vm._v(" " + _vm._s(_vm.piRate) + " "),
                  ]),
                  _c("div", { staticClass: "colunm" }, [
                    _vm._v(" " + _vm._s(_vm.hapiNumber) + " "),
                  ]),
                  _c("div", { staticClass: "colunm" }, [
                    _vm._v(" " + _vm._s(_vm.hapiRate) + " "),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "table-paging" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }