<template>
  <div class="data-detail">
    <div
      slot="header"
    >
      <div
        class="flex mb-5 search-bar"
      >
        <div class="flex search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >问卷ID</span>
          <el-input
            v-model="search.formNo"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="fetchPageInfo"
          />
        </div>
        <div class="search-item">
          <span
            class="search-item__label"
          >调查机构</span>

          <el-select
            v-model="search.orgCode"
            size="small"
            style="width: 110px;"
            filterable
            clearable
          >
            <el-option
              v-for="(item) in orgList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </div>
        <div class="flex search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >姓名</span>
          <el-input
            v-model="search.userName"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="fetchPageInfo"
          />
        </div>
        <div class="flex search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >手机号</span>
          <el-input
            v-model="search.cellphone"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="fetchPageInfo"
          />
        </div>
      </div>
      <div
        class="flex search-bar"
      >
        <div class="search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >问卷状态</span>
          <el-select
            v-model="search.completeStatus"
            size="small"
            style="width: 120px;"
            clearable
          >
            <el-option
              v-for="(item, key) in STATUS_MAP"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </div>
        <div class="search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >提交时间</span>
          <el-date-picker
            v-model="createTime"
            type="daterange"
            align="right"
            size="small"
            unlink-panels
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="PickerOptions"
            value-format="yyyy-MM-dd"
            @change="timePicker($event, 'createTime')"
          />
        </div>
        <div class="search-item">
          <span
            class="search-item__label"
          >最近更新时间</span>
          <el-date-picker
            v-model="updateTime"
            type="daterange"
            align="right"
            size="small"
            unlink-panels
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="PickerOptions"
            value-format="yyyy-MM-dd"
            @change="timePicker($event, 'updateTime')"
          />
          <!-- @change="timePicker" -->
        </div>
        <div class="flex flex-auto justify-end">
          <el-button
            size="small"
            @click="clearSearch"
          >
            重置
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="fetchPageInfo"
          >
            查询
          </el-button>
        </div>
      </div>
    </div>
    <el-card class="mt-4">
      <div class="flex mb-4 item-centers">
        <div class="flex items-center text-base">
          调查列表
          <div class="status-text">
            共 {{ countDetail.total }} 份问卷，已完成：<span
              class="blue"
              @click="filterCompleteList"
            >{{ countDetail.complete }}</span>, 进行中：<span
              class="blue"
              @click="filterIncompleteList"
            >{{ countDetail.incomplete }}</span>
          </div>
        </div>
        <div
          class="flex flex-auto justify-end"
          style="flex: 1;"
        >
          <el-button
            v-per="'surveya002'"
            v-loading.fullscreen.lock="fullscreenLoading"
            type="primary"
            size="small"
            @click="exportForm()"
          >
            导出问卷
          </el-button>
        </div>
      </div>
      <table-list
        :data="tableData"
        :loading="loading"
        :form-code="formCode"
        @view="viewSurveyDetail"
        @showCellphone="showFullCellPhone"
      />
      <div class="pagination">
        <el-pagination
          :current-page="pageBean.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageBean.size"
          layout="total, sizes, prev, pager, next"
          :total="pageBean.total"
          @size-change="changePageSize($event, 'answerList')"
          @current-change="changePage($event, 'answerList')"
        />
      </div>
    </el-card>
    <!-- 导出数据确认框 -->
    <ExportDataDialog
      ref="export"
      @confirm="exportConfirm"
    />
    <AfterDownloadDialog
      ref="after"
    />
  </div>
</template>

<script>
import apiCommon from '@/api/common.js'
import TableList from './components/table-list'
import ExportDataDialog from '@/components/export-data-confirm-dialog/index'
import AfterDownloadDialog from '@/components/after-download-dialog/index'

import { PickerOptions, SexEnum } from '@/config/constant.js'
import { downloadSubscribe } from '@/utils/download.js'

export default {
  name: 'Home',
  components: {
    TableList,
    ExportDataDialog,
    AfterDownloadDialog
  },
  data () {
    return {
      fullscreenLoading: false,
      formCode: '',
      STATUS_MAP: {
        0: '进行中',
        1: '已完成'
      },
      SexEnum,
      tableData: [],
      loading: false,
      search: {
        formNo: '',
        userName: '',
        cellphone: '',
        orgCode: '',
        completeStatus: null
      },
      createTime: [],
      updateTime: [],
      PickerOptions,
      orgList: {},
      pageBean: {
        page: 1,
        size: 20,
        total: 0
      },
      countDetail: {
        total: 0,
        incomplete: 0,
        complete: 0
      }
    }
  },
  mounted () {
    this.getOrgList()
    const {
      status,
      org
    } = this.$route.query

    this.search = {
      ...this.search,
      completeStatus: status || status === 0 ? status + '' : null,
      orgCode: org
    }
    this.fetchPageInfo()
  },
  methods: {
    filterCompleteList () {
      this.search.completeStatus = '1'
      this.fetchPageInfo()
    },
    filterIncompleteList () {
      this.search.completeStatus = '0'
      this.fetchPageInfo()
    },
    async getOrgList () {
      const resp = await apiCommon.getOrgList()
      if (resp.success) {
        this.orgList = resp.data
      }
    },
    fetchPageInfo () {
      this.searchList()
      this.getSurveyListCount()
    },
    timePicker (v, prop) {
      this[prop] = v || []
    },
    clearSearch () {
      this.search.formNo = ''
      this.search.userName = ''
      this.search.cellphone = ''
      this.search.orgCode = ''
      this.search.completeStatus = null
      this.createTime = []
      this.updateTime = []
      this.page = 1
      this.fetchPageInfo()
    },
    async getSurveyListCount () {
      const searchParams = this.getSearchParams()

      const resp = await apiCommon.getSurveyCount(searchParams)
      if (resp.success) {
        this.countDetail = resp.data
      }
    },
    getSearchParams () {
      return {
        ...this.search,
        completeStatus: this.search.completeStatus === null ? '' : +this.search.completeStatus,
        page: this.pageBean.page,
        size: this.pageBean.size,
        fillStartTime: this.createTime && this.createTime[0],
        fillEndTime: this.createTime && this.createTime[1],
        updateStartTime: this.updateTime && this.updateTime[0],
        updateEndTime: this.updateTime && this.updateTime[1],
        orgCode: this.search.orgCode === 'all' ? null : this.search.orgCode
      }
    },
    encryptPhone (phone) {
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    },
    formatTableList (rawData) {
      return rawData.map(item => {
        return {
          ...item,
          encryptPhone: this.encryptPhone(item.cellphone),
          isShowFullPhone: false
        }
      })
    },
    async searchList () {
      this.loading = true
      const searchParams = this.getSearchParams()
      try {
        const resp = await apiCommon.getSurveyList(searchParams)
        if (resp.success) {
          this.tableData = this.formatTableList(resp.data)
          this.pageBean.total = resp.paging.total
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    exportConfirm (type) {
      switch (type) {
        case 'know':
          this.exportPdf(1)
          break
        case 'que':
          this.exportForm(1)
          break
        case 'img':
          this.exportImg(1)
          break
      }
    },
    async exportForm (f) {
      if (f) {
        this.fullscreenLoading = true
        const res = await apiCommon.exportSurveyData(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiCommon.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
          console.log('object', e)
        // this.$message.error(e)
        } finally {
          this.fullscreenLoading = false
        }
      } else {
        // 弹窗、问卷数据
        this.$refs.export.setData({ ctype: 'que', username: '' })
      }
    },
    showFullCellPhone (row) {
      this.tableData = this.tableData.map(item => {
        if (item.formNo === row.formNo) {
          item.isShowFullPhone = !item.isShowFullPhone
        }
        return item
      })
    },
    viewSurveyDetail (row) {
      this.$router.push({
        path: '/common/survey-detail',
        query: {
          formCode: row.formCode,
          formVersion: row.formVersion,
          slug: row.slug
        }
      })
    },
    changePageSize (size, type) {
      this.pageBean.size = size
      this.pageBean.page = 1
      this.fetchPageInfo()
    },
    changePage (page, type) {
      this.pageBean.page = page
      this.fetchPageInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-detail {
  padding: 20px;
  background-color: #F7F8FA;
  .version-switch {
    // margin-bottom: 21px;
  }
  .data-overview {
    .overview-item {
      box-sizing: border-box;
      padding: 16px 14px;
      background: #f7f8fa;
      border: 1px solid #ffffff;
      border-radius: 4px;
      flex: 1;
      margin: 0 12px 12px 0;
      width: calc((100% - 36px) / 4);
      min-width: calc((100% - 36px) / 4);
      max-width: calc((100% - 36px) / 4);
      &:nth-child(4) {
        margin-right: 0;
      }

      &-title {
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 22px;
      }

      .number-unit {
        .number {
          // width: 51px;
          // height: 38px;
          font-size: 30px;
          font-family: D, D-Bold;
          font-weight: 700;
          text-align: left;
          color: rgba(0,0,0,0.85);
          line-height: 38px;
        }
        .unit {
          // width: 14px;
          // height: 22px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }
      }
    }
  }
}

.blue {
  color: #409eff;
}

.status-text {
  margin-left: 10px;
}
</style>
