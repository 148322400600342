import { Message } from 'element-ui'

import { createYLAjax } from '@cmvalue/dc-ajax'

if (process.env.VUE_APP_ENV === 'development') {
  const devToken = 'ZXlKeloxV1ZrSWpvaU1UUTBJaXdpWVd4bklqb2lTRk0xTVRJaUxDSjZhWEFpT2lKSFdrbFFJbjAuSDRzSUFiQUFBQUFBQUFIMVN5MDdDUUJUOWx3bExJSVZTd080VVdDQVBHMUFTVFJOVHBoY29scGxtT3VVWkV0ZHVqUDZBU3pfQWJ4Sl93LWxNZVlrNnEzdlB1WFB2dVdkbWhjS29qMHkwc3BHRE1ZMEliMU5TbXdjZUE5ZEc1c0R4UTBnZmNrMktINDZwaUk4bzg1YmdkaUNrRWNNZ1NObk80b2RsUVZDaGJzelo2Tnl5Y2paSzdoNmg1blZIeS0tNUt6WnN3RUt4MVpZWlYtREo5TjRZMzFFOHkzZGxYZDliOW9ESkdwbmppSWxyU2dQZTl1NVdXajNKRW1laWtNX1g1ODNieDlmN3ktYnAwVVpyUWJrVXQ2bmticHV0VExXVDBkVFJqWUs4S3ZnYno1VUZKeUltenBqdXg2WVlER1JkS252cVRuWXJNSjRaOElPUlZ1M25TQVpobll5QWVWejFUbFprMUZkTFZEdTdmR2ZqM3NGcFlrdXVwT3RsVFM4WVpUVVVnLThISTByZ3dEVEtna1JINDFJaDRnTUE0WjU0dmw5X0JCQ243MHVFc3lnR2ZEcWtKTmxHVERSS1phTlVWRUlDSnd4bmxQM2hYUlFDcV9fRDdaNU1xRWZwZUN2eFgzTWk0Z3NSWEZRc0VRS1ppcGhEeU9Oa0hpQlRTQ2pxWjVwUnlrbGdnc3lpVmloTGQ5UEMxNEVBQ25wZTV1dHZocE8yWVFVREFBQS5xSVJZNVR4ZGtEVUt3d2Q3NjJKUmdmb0I1WjFZOTFacS1LcldSY0hJUmNaT2JDM2dnVmxTbDZibk9yVEpaMU9ucGEtUXVqTnJadUpyQmNGN05wSHlvcUE='
  localStorage.setItem('x-b-token', devToken)
}

/**
 * TODO:
 * 以下配置根据项目具体需求修改
 * 具体配置项可点击 http://npm.cmvalue.net/-/web/detail/@cmvalue/dc-ajax 查看。
 */

const YLHttp = createYLAjax({
  requestOptions: {
    apiUrl: '',
    isTransformResponse: false,
    bizOptions: {
      platform: 'pc',
      orgCode: 'CMV',
      isMiddlePlatform: true,
      appCode: process.env.VUE_APP_ENV === 'production' ? 'APP22' : 'APP40'
    },
    env: process.env.VUE_APP_ENV === 'production' ? 'prd' : 'test'
  },
  customFn: {
    // TODO: 传入 Vant 的 Toast 或者 Element Ui 的 Message
    message: Message
    // login: (pa) => {
    //   console.log('登录回调', pa)
    // }
  }
})

export default YLHttp
