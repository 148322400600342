<template>
  <div class="statistic">
    <div
      v-if="showBlock"
      class="statistic-block-container"
    >
      <div class="statistic-block">
        <div class="statistic-title">
          患者人数
        </div>
        <div class="statistic-content">
          <div class="statistic-content-number">
            {{ patientNumber }}
          </div>
          <div class="statistic-content-unit">
            人
          </div>
        </div>
      </div>

      <div class="statistic-block">
        <div class="statistic-title">
          PI 人数
        </div>
        <div class="statistic-content">
          <div class="statistic-content-number">
            {{ piNumber }}
          </div>
          <div class="statistic-content-unit">
            人
          </div>
        </div>
      </div>

      <div class="statistic-block">
        <div class="statistic-title">
          PI 患病率
        </div>
        <div class="statistic-content">
          <div class="statistic-content-number">
            {{ piRate }}
          </div>
          <div class="statistic-content-unit" />
        </div>
      </div>

      <div class="statistic-block">
        <div class="statistic-title">
          HAPI 人数
        </div>
        <div class="statistic-content">
          <div class="statistic-content-number">
            {{ hapiNumber }}
          </div>
          <div class="statistic-content-unit">
            人
          </div>
        </div>
      </div>

      <div class="statistic-block">
        <div class="statistic-title">
          HAPI 患病率
        </div>
        <div class="statistic-content">
          <div class="statistic-content-number">
            {{ hapiRate }}
          </div>
          <div class="statistic-content-unit" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="statistic-inline"
    >
      <span class="statistic-inline-text">合计患者人数 </span>
      <span class="statistic-inline-number">
        {{ patientNumber }}
      </span>
      <span class="statistic-inline-text">人，PI 人数 </span>
      <span class="statistic-inline-number">
        {{ piNumber }}
      </span>
      <span class="statistic-inline-text"> 人，PI 患病率 </span>
      <span class="statistic-inline-number">
        {{ piRate }}
      </span>
      <span class="statistic-inline-text">，HAPI 人数 </span>
      <span class="statistic-inline-number">
        {{ hapiNumber }}
      </span>
      <span class="statistic-inline-text"> 人，HAPI 患病率 </span>
      <span class="statistic-inline-number">
        {{ hapiRate }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBlock: {
      type: Boolean,
      default: true
    },
    patientNumber: {
      type: String,
      default: ''
    },
    piNumber: {
      type: String,
      default: ''
    },
    piRate: {
      type: String,
      default: ''
    },
    hapiNumber: {
      type: String,
      default: ''
    },
    hapiRate: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.statistic {
  padding-inline: 14px;
  margin-block: 24px;
  .statistic-block-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    width: 100%;;
    .statistic-block {
      height: 110px;
      background: #f8f8fa;
      border: 1px solid #efefef;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 21px 13px;
      flex: 1;
      gap: 11px;
      .statistic-title {
        font-size: 14px;
        font-weight: 500;
        color: #333;
        line-height: 22px;
      }
      .statistic-content {
        display: flex;
        align-items: flex-end;
        .statistic-content-number {
          font-size: 30px;
          font-weight: 500;
          color: #333;
          line-height: 38px;
          font-weight: 500;
        }
        .statistic-content-unit {
          font-size: 14px;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }
      }
    }
  }
  .statistic-inline {
    .statistic-inline-text {
      font-size: 14px;
      color: #333;
      line-height: 20px;
    }
    .statistic-inline-number {
      font-size: 14px;
      color: #409EFF;
      line-height: 20px;
    }
  }
}
</style>
