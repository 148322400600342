import ajax from '@/libs/ajax'

/**
 * 名称: 获取机构列表
 * 描述: []
 * 作者: zhanglh
 */
export const getHospitalList = () => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/organization/list/array',
    method: 'GET'
  })
}

export const getYearList = () => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/year/list',
    method: 'GET'
  })
}

export const getHospitalLevelList = () => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/organization/hospital/grade',
    method: 'GET'
  })
}

/**
 * 名称: 科室PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param year: 年度，例如:"2024" 非必填 string
 * @param quarter: 季度，例如:"Q1" 非必填 string
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 */
export const getPIDepartmentList = ({ year, quarter, sortField, sort }) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/section/pi',
    method: 'POST',
    data: {
      year,
      quarter,
      sortField,
      sort
    }
  })
}

/**
 * 名称: 指定医院科室PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param year: 年度，例如:"2024" 非必填 string
 * @param quarter: 季度，例如:"Q1" 非必填 string
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param orgCode: 机构编码 必填 string
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPIDepartmentListForHospital = ({
  year,
  quarter,
  sortField,
  sort,
  orgCode,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/hospital/section-pi',
    method: 'POST',
    data: {
      year,
      quarter,
      sortField,
      sort,
      orgCode,
      page,
      size
    }
  })
}

/**
 * 名称: 医院PI 患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param year: 年度，例如:"2024" 非必填 string
 * @param quarter: 季度，例如:"Q1" 非必填 string
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param orgCodes: 机构编码 非必填 array
 * @param hospitalGrades: 医院等级 非必填 array
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPIHospitalList = ({
  year,
  quarter,
  sortField,
  sort,
  orgCodes,
  hospitalGrades,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/hospital/pi',
    method: 'POST',
    data: {
      year,
      quarter,
      sortField,
      sort,
      orgCodes,
      hospitalGrades,
      page,
      size
    }
  })
}

/**
* 名称: 指定医院科室PI患病率列表接口
* 描述: []
* 作者: zjlxfulp
* @param year: 年度，例如:"2024" 非必填 string
* @param quarter: 季度，例如:"Q1" 非必填 string
* @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
* @param sort: 正序:asc,倒序:desc 非必填 string
* @param orgCode: 机构编码 必填 string
* @param page: undefined 非必填 string
* @param size: undefined 非必填 string
*/
export const getPIUniqueDepartmentList = ({
  year,
  quarter,
  sortField,
  sort,
  orgCode,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/hospital/section-pi',
    method: 'POST',
    data: {
      year,
      quarter,
      sortField,
      sort,
      orgCode,
      page,
      size
    }
  })
}

/**
 * 名称: 指定医院科室护理单元PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param orgCode: 机构编码 必填 string
 * @param sectionType: 科室 必填 string
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPINurseUnitList = ({
  sortField,
  sort,
  orgCode,
  sectionType,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/hospital/nurse-cell-pi',
    method: 'POST',
    data: {
      sortField,
      sort,
      orgCode,
      sectionType,
      page,
      size
    }
  })
}

/**
 * 名称: 指定医院科室护理单元PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param orgCode: 机构编码 必填 string
 * @param sectionType: 科室 必填 string
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPINurseUnitListForHospital = ({
  sortField,
  sort,
  orgCode,
  sectionType,
  year,
  quarter,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/hospital/nurse-cell-pi',
    method: 'POST',
    data: {
      sortField,
      sort,
      orgCode,
      sectionType,
      year,
      quarter,
      page,
      size
    }
  })
}

/**
 * 名称: 用户所在医院的科室PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param year: 年度，例如:"2024" 非必填 string
 * @param quarter: 季度，例如:"Q1" 非必填 string
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPIDepartmentListEntry = ({
  year,
  quarter,
  sortField,
  sort,
  page,
  size
}) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/user/section-pi',
    method: 'POST',
    data: {
      year,
      quarter,
      sortField,
      sort,
      page,
      size
    }
  })
}

/**
 * 名称: 用户所在医院科室的护理单元PI患病率列表接口
 * 描述: []
 * 作者: zjlxfulp
 * @param sortField: 排序字段 患者人数：pCnt, PI人数：piCnt，Pi患病率：piBl，HAPI人数：hapiCnt，HAPI患病率：hapiBl 非必填 string
 * @param sort: 正序:asc,倒序:desc 非必填 string
 * @param sectionType: 科室 必填 string
 * @param page: undefined 非必填 string
 * @param size: undefined 非必填 string
 */
export const getPINurseUnitListForDepartmentEntry = ({ sortField, sort, sectionType, page, size }) => {
  return ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/user/nurse-cell-pi',
    method: 'POST',
    data: {
      sortField,
      sort,
      sectionType,
      page,
      size
    }
  })
}
