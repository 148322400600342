<template>
  <div class="data-detail">
    <div
      slot="header"
    >
      <div
        class="flex mb-5 search-bar"
      >
        <div class="flex search-item">
          <span
            class="search-item__label"
            style="width: 60px;"
          >医生ID</span>
          <el-input
            v-model="search.doctorId"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
        <div class="flex search-item">
          <span
            class="search-item__label"
            style="width: 80px;"
          >医生名称</span>
          <el-input
            v-model="search.doctorName"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
        <div class="search-item">
          <span
            class="search-item__label"
          >机构</span>
          <el-input
            v-model="search.orgName"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
      </div>
      <div
        class="flex search-bar"
      >
        <div
          class="flex flex-auto justify-end"
          style="flex: 1;"
        >
          <el-button
            size="small"
            @click="clearSearch"
          >
            重置
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="searchList"
          >
            查询
          </el-button>
        </div>
      </div>
    </div>
    <el-card class="mt-4">
      <div class="flex mb-4 item-centers">
        <div class="flex items-center text-base">
          医生列表
        </div>
        <div
          class="flex flex-auto justify-end"
          style="flex: 1;"
        >
          <el-button
            v-per="'surveya010'"
            type="primary"
            size="small"
            plain
            @click="addItem()"
          >
            新增
          </el-button>
          <el-button
            v-per="'surveya014'"
            type="primary"
            plain
            size="small"
            @click="importWhite()"
          >
            导入白名单
          </el-button>
        </div>
      </div>
      <table-list
        :data="tableData"
        :loading="loading"
        @view="openDetail"
        @delete="deleteItem"
      />
      <div class="pagination">
        <el-pagination
          :current-page="pageBean.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageBean.size"
          layout="total, sizes, prev, pager, next"
          :total="pageBean.total"
          @size-change="changePageSize($event, 'answerList')"
          @current-change="changePage($event, 'answerList')"
        />
      </div>
    </el-card>
    <DoctorForm
      ref="doctor"
      @close="changeVersion"
    />
    <!-- 导入数据确弹窗 -->
    <ImportDialog
      ref="import"
    />
    <!-- @confirm="exportConfirm" -->
  </div>
</template>

<script>
import apiCommon from '@/api/common.js'
import TableList from './components/table-list'
import DoctorForm from './components/doctor-form'
import ImportDialog from '@/components/import-dialog/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    TableList,
    ImportDialog,
    DoctorForm
  },
  data () {
    return {
      orgnizations: {},
      tableData: [],
      loading: false,
      search: {
        doctorId: '',
        doctorName: '',
        orgName: ''
      },
      pageBean: {
        page: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    ...mapState('app', {
      roleMap: state => state.white.roleMap
    })
  },
  watch: {
    '$route.query.t' (t) {
      this.getRoleList()
      this.clearSearch()
    }
  },
  async created () {
    this.getRoleList()
    this.changeVersion()
  },
  methods: {
    clearSearch () {
      this.search.doctorId = ''
      this.search.doctorName = ''
      this.search.orgName = ''
      this.page = 1
      this.searchList()
    },
    changeVersion (v) {
      this.searchList()
    },
    async getRoleList () {
      const resp = await apiCommon.getRoleList()
      if (resp.success) {
        this.$store.dispatch('app/setData', {
          white: {
            roleMap: resp.data
          }
        })
      }
    },
    getSearchParams () {
      return {
        ...this.search,
        page: this.pageBean.page,
        size: this.pageBean.size
      }
    },
    async searchList () {
      this.loading = true
      const serch = this.getSearchParams()

      try {
        const resp = await apiCommon.getWhiteList(serch)
        if (resp.success) {
          this.tableData = resp.data
          this.pageBean.total = resp.paging.total
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async addItem (f) {
      // 弹窗表单
      this.$refs.doctor.setData()
    },
    async deleteItem (row) {
      const resp = await apiCommon.deleteWhiteItem({ id: row.id })
      if (resp.success) {
        this.$message.success('删除成功')
        this.searchList()
      }
    },
    async importWhite (f) {
      // 导入白名单弹窗
      console.log('------ importWhite')
      const tempUrl = 'https://cdn.cmvalue.com/file/xlsx/whitelist_import_survey.xlsx'
      this.$refs.import.setData(tempUrl)
    },
    openDetail (row) {
      this.$refs.doctor.setData(row)
      // this.$refs.detail.setData(row)
    },
    changePageSize (size, type) {
      this.pageBean.size = size
      this.pageBean.page = 1
      this.searchList()
    },
    changePage (page, type) {
      this.pageBean.page = page
      this.searchList()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-detail {
  padding: 20px;
  background-color: #F7F8FA;
  .version-switch {
    // margin-bottom: 21px;
  }
  .data-overview {
    .overview-item {
      box-sizing: border-box;
      padding: 16px 14px;
      background: #f7f8fa;
      border: 1px solid #ffffff;
      border-radius: 4px;
      flex: 1;
      margin: 0 12px 12px 0;
      width: calc((100% - 36px) / 4);
      min-width: calc((100% - 36px) / 4);
      max-width: calc((100% - 36px) / 4);
      &:nth-child(4) {
        margin-right: 0;
      }

      &-title {
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 22px;
      }

      .number-unit {
        .number {
          // width: 51px;
          // height: 38px;
          font-size: 30px;
          font-family: D, D-Bold;
          font-weight: 700;
          text-align: left;
          color: rgba(0,0,0,0.85);
          line-height: 38px;
        }
        .unit {
          // width: 14px;
          // height: 22px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
