var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            width: "410px",
            title: _vm.disabled ? "白名单详情" : "新增白名单",
            visible: _vm.show,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: true,
            "custom-class": "doc-form-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "医生ID", prop: "doctorId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.doctorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "doctorId", $$v)
                      },
                      expression: "form.doctorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "doctorName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.doctorName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "doctorName", $$v)
                      },
                      expression: "form.doctorName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "机构编码", prop: "orgCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.orgCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orgCode", $$v)
                      },
                      expression: "form.orgCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "机构名称", prop: "orgName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orgName", $$v)
                      },
                      expression: "form.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "医生角色", prop: "role" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择角色" },
                      model: {
                        value: _vm.form.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role", $$v)
                        },
                        expression: "form.role",
                      },
                    },
                    _vm._l(_vm.roleMap, function (val, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: val, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.role
                ? _c(
                    "el-form-item",
                    { attrs: { label: "角色编码" } },
                    [
                      _c("el-input", {
                        staticStyle: { display: "inline-block", width: "80%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.role,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "role", $$v)
                          },
                          expression: "form.role",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.form.role,
                              expression: "form.role",
                              arg: "copy",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.handleCopySuccess,
                              expression: "handleCopySuccess",
                              arg: "success",
                            },
                          ],
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "per",
                  rawName: "v-per",
                  value: "surveya011",
                  expression: "'surveya011'",
                },
              ],
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }